@use './partials/mixins' as *;

*, ::after, ::before {
    box-sizing: border-box;
}

body {
    font-family: "Afacad Flux", sans-serif;
    margin: 0;
}

h1, h2, h3, h4, h5 {
    font-family: "Barlow Condensed", serif;
}

.main {
    & > img:first-child {
        width: 100%;
    }
}

br {
    @include mobile {
        display: none;
    }
}

.character-break {
    hyphens: auto;
    overflow-wrap: anywhere;
}

.overlay--hidden {
    display: none;
}

.overlay {
    position: fixed; 
    display: block; 
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); 
    z-index: 2; 
    cursor: pointer;
  }