@use '../../styles/partials/mixins' as *;

.donateSection {
    @include backgroundImage;
    padding: 3rem 6.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    @include mobile {
        justify-content: center;
        text-align: center;
        padding: 3rem 1rem;
    }
    & > div:first-child {
        position: relative;
        z-index: 1;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        @include desktop {
            display: block;
            text-align: unset;
            width: auto;
        }
        & > h2 {
            color: #FFFFFF;
            margin-top: 0;
            line-height: 55px;
            font-size: 2.5rem;
            margin-bottom: 3rem;
            font-weight: 700;
            @include mobile {
                line-height: 35px;
            }
        }
    }
    &__links {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin-bottom: 1rem;
        @include mobile {
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
        }
        & a {
            background-color: #EF6CEF;
            text-align: center;
            @include buttons;
        }
    }
    & > div:last-child {
        & > img {
            position: absolute;
            bottom: 0%;
            right: 0%;
            width: 26rem;
            @include mobile {
                width: 54%;
            }
        }
    }
    
}