@use '../../styles/partials/mixins' as *;

.footer {
    background-image: url('/assets/images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    padding: 3rem 6.5rem;
    @include mobile {
        padding: 3rem 1rem;
    }
    &__contact {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: unset;
        @include desktop {
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 3rem;
            width: 65%;
        }
    }
    & > img {
        @include mobile {
            width: 100%;
        }
    }
    & > article {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
        width: 100%;
        @include tablet {
            flex-direction: column;
        }
        @include mobile {
            flex-direction: column;
        }
    }
    &__aboutus {
        color: #FFFFFF;
        & > h3 {
            margin-bottom: 0.5rem;
            @include footer-header;
        }
        & > p {
            @include desktop {
                width: 70%;
                min-width: 14rem;
            }
            @include body-text;
        }
    }
    &__location {
        @include mobile {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }
        & h3 {
            @include footer-header;
            color: #FFFFFF;
        }
        & p, a {
            color: #FFFFFF;
            @include body-text;
        }
        & > div {
            display: flex;
            flex-direction: row;
            gap: 1.5rem;
            @include mobile {
                flex-wrap: wrap;
            }
        }
    }
    &__donate {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        color: #FFFFFF;
        width: 100%;
        @include desktop {
            width: 20%;
        }
        &--link {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 0.5rem;
            @include desktop {
                flex-direction: column;
            }
        }
        & > div:first-child {
            @include footer-header;
            margin-top: 1rem;
            font-weight: 700;
            font-size: 1.6rem;
            @include desktop {
                font-size: 1rem;
                line-height: 18px;
            }
        }
        & a {
            background-color: #EF6CEF;
            text-align: center;
            @include buttons;
            padding: 0.9rem;
            // width: 12rem;
            @include desktop {
                line-height: 6px;
                font-size: 0.8rem;
                width: 9rem;
            }
        }
    }
}


.location {
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    font-weight: 500;
    @include mobile {
        flex-wrap: wrap;
    }
    & > div:first-child {
        @include body-text;
        // line-height: 18px;
    }
    & > p {
        margin: 0 0 1rem 0;
        @include body-text;
        @include desktop {
            white-space: nowrap;
        }
    }
    & > a:nth-child(2) {
        text-decoration: none;
        @include body-text;
    }
}