@use '../../styles/partials/mixins' as *;

.socialIcons {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &__link {
        & img {
            
        }
    }
}