@use '../../styles/partials/mixins' as *;

.rippleSection {
    background-color: #A621F6;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
        text-align: center;
        padding: 3rem 1rem;
    }

    & > div:first-child {
        width: 53rem;
        @include tablet {
            width: 70%;
        }
        @include mobile {
            width: 100%;
        }
        & > article {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 2rem 0rem;
            @include desktop {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-align: unset;
            }
            @include mobile {
                padding: 0rem;
            }
            & br {
                @include tablet {
                    display: none;
                }
            }
            & img {
                width: 100%;
                @include desktop {
                    width: 24rem;
                }
            }
        }
    }
    &__heading {
        @include secondary-header;
        margin-bottom: 1rem;
        margin-top: 0;
        color: #FFFFFF;
        @include mobile {
            width: 100%;
        }
    }
    &__text {
        @include body-text;
        color: #FFFFFF;
        margin: 2rem 0rem;
        @include desktop {
            padding: inherit;
            margin-bottom: 0;
        }
        @include mobile {
            width: 100%;
        }
    }
}