@use '../../styles/partials/mixins' as *;

.popupEvent {
    position: fixed;
    z-index: 2;
    padding: 0;
    border: unset;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    max-width: 1200px;
    width: 100%;
    max-height: 90vh;
    overflow: scroll;
    transform: translate(-50%, -50%);
    @include mobile {
        max-height: unset;
        width: 90%;
    }

    & > div {
        display: flex;
        justify-content: space-evenly;
        @include mobile {
            flex-direction: column-reverse;
            height: 90vh;
        }
        & > div:first-child {
            width: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FEFEFE;
            text-align: center;
            @include mobile {
                width: 100%;
                height: 50%
            }
            & p {
                @include secondary-header;
                @include tablet {
                    font-size: 2rem;
                    line-height: unset;
                }
                @include mobile {
                    font-size: 1rem;
                    line-height: unset;
                }
            }
            & a {
                text-decoration: none;
                @include buttons;
                background-color: #FF40FF;
            }
        }
    }
    & img {
        width: 50%;
        @include mobile {
            width: 100%;
            height: 50%;
        }
    }
    &--close {
        position: absolute;
        top: 15px;
        right: 15px;
        color: #fff;
        display: block;
        cursor: pointer;
        background: unset;
        border: 2px solid #fff;
        border-radius: 35px;
        font-size: 18px;
    }
}