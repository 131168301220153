@use '../../styles/partials/mixins' as *;

.header {
    @include backgroundImage;
    margin-top: 0%;
    padding: 1.5rem 1.5rem 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    overflow: hidden;
    & > div {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        & > img:first-child {
            width: 15rem;
            margin-bottom: 2rem;
        }
        & > img:nth-child(2) {
            width: 11rem;
            margin-bottom: 2rem;
        }
    }
    & > img:last-child {
        position: absolute;
        right: 0%;
        bottom: 0%;
        width: 90%;
        max-width: 70rem;
    }
    &__mainText {
        font-family: "Barlow Condensed", serif;
        color: #FFFFFF;
        font-size: 5rem;
        font-weight: 700;
        letter-spacing: 0.05rem;
        line-height: 80px;
        @include mobile {
            width: 100%;
        }
    }

    &__paragraph {
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 30px;
        color: #FFFFFF;
        @include mobile {
            width: 90%;
            font-size: 1.7rem;
        }
    }
    &__links {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        @include mobile {
            flex-wrap: wrap;
            justify-content: center;
        }
        & a {
            background-color: #33105B;
            @include buttons;
        }
    }
}