@mixin backgroundImage {
    background-image: url('/assets/images/background.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
}

@mixin body-text {
    font-size: 1.6rem;
    line-height: 40px;
    font-weight: 500;
    @include desktop {
        font-size: 1rem;
        line-height: 18px;
    }
}
@mixin secondary-header {
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 60px;
    @include desktop {
        line-height: 55px;
        font-size: 3.5rem;

    }
}
@mixin footer-header {
    font-size: 1.2rem;
    font-weight: 800;
    font-family: "Afacad Flux", sans-serif;
    @include mobile {
        font-size: 1.8rem;
    }
}
@mixin buttons {
    display: block;
    font-weight: 700;
    font-size: 1rem;
    padding: 0.7rem;
    border-radius: 2rem;
    text-decoration: none;
    color: #FFFFFF;
    width: 12rem;
    &:hover {
        cursor: pointer;
    }
}

// variables for breakpoints
$mobile-breakpoint: 760px;
$tablet-breakpointmax: 1024px;
$tablet-breakpointmin: 761px;
$desktop-breakpoint: 1025px;

// mixins for each breakpoint in the responsive design
@mixin mobile {
    @media screen and (max-width: $mobile-breakpoint) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: $tablet-breakpointmax) and (min-width: $tablet-breakpointmin){
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width: $desktop-breakpoint){
        @content;
    }
}