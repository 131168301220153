@use '../../styles/partials/mixins' as *;

.helpSection {
    background-color: #EF6CEF;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @include mobile {
        padding: 3rem 2.5rem;
    }

    & > h2 {
        color: #3E1C66;
        @include secondary-header;
        margin: 0 0 0.8rem 0;
    }
    & > p {
        @include body-text;
        color: #3E1C66;
        margin: 0rem;
        @include mobile {
            width: 77%;
        }
    }
    &__icons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 2rem;
        width: 100%;
        @include desktop {
            width: 53rem;
        }
        @include tablet {
            justify-content: space-around;
        }
        @include mobile {
            margin-top: 3rem;
        }
        // & div:first-child, div:nth-child(2), div:nth-child(3) {
        //     & > p {
        //         & a {
        //             text-decoration: none;
        //             &:hover {
        //                 text-decoration: underline;
        //             }
        //         }
        //     }
        // }
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: 20%;
            @include tablet {
                width: 40%;
            }
            // @include tablet {
            //     width: 40%;
            // }
            @include mobile {
                width: 100%;
            }
            & > img {
                width: 3.5rem;
                @include mobile {
                    width: 5.5rem;
                }
            }
            & > div {
                color: #3E1C66;
                @include body-text;
                font-weight: 800;
                margin-top: 0.5rem;
            }
            & > p {
                color: #3E1C66;
                margin-top: 0.5rem;
                @include body-text;
                & a {
                    color: #3E1C66;
                }
            }
        }
    }
}